import React from 'react'
import { navigate } from 'gatsby'
import {Layout} from 'widgets'
import { SEO, Icon, Image, Input, Textarea, Button, IntlTelInput} from 'components'
import {ContactBanner, AirplaneSeparator, ContactOkMail} from 'images'
import {IntlTelCountry} from 'models'
import {WHATSAPP_LINK, CALENDLY_LINK, WHATSAPP_PHONE, quitLoading, setLoading, showError, showSuccess} from 'utils'
import {ContactService} from 'services'

type Form = {
    country: null | IntlTelCountry,
    country_phone: string,
    name: string,
    email: string,
    message: string
}
const Contact = () => {
    const IFormState: Form = {
        country: null,
        country_phone: '',
        name: '',
        email: '',
        message: ''
    }
    const defaultIntlClass = 'intl-tel-input border-gray form-control'
    const invalidIntlClass = defaultIntlClass  +' invalid-feedback '
    const [intlClassName, setIntlClassName] = React.useState<string>(defaultIntlClass)
    const [form, setForm] = React.useState<Form>(IFormState)
    const [isOk, setIsOk] = React.useState<boolean>(false)
    const onChangeIntl = (isCorrect: boolean, country_phone: string, country: IntlTelCountry) => {
        console.log(isCorrect, country_phone, country)
        if(isCorrect){
            let _form: Form = {
                ... form,
                country,
                country_phone
            }
            setForm(_form)
            setIntlClassName(defaultIntlClass)
        }else{
            setIntlClassName(invalidIntlClass)
        }
    }
    const change = (key: keyof Form, value: string) => {
        const _form: Form = {
            ... form,
            [key]: value
        }
        setForm(_form);
    };
    const submit = async () => {
        setLoading()
        try {
            if(form.country){
                const params = {
                    nombre: form.name,
                    email: form.email,
                    whatsapp: +'+'+form.country?.dialCode+''+form.country_phone,
                    mensaje: form.message,
                    origen: form.country.name,
                    ciudad: ' '
                }
                const res = await ContactService.send(params)
                showSuccess()
                // navigate('/')
                setIsOk(true)
            }
        } catch (error) {
            showError(error?.message)
        }finally{
            quitLoading()
        }
    }
    return(
        <Layout>
            <SEO
                title="Contacto Compara Assist ¡Comunícate con nosotros!"
                description="¿Tienes dudas sobre tu plan? Compara entre distintas coberturas, contáctanos y obtén más información sobre nuestros proveedores y servicios."
                keywords="Contacto Web, Comunícate con nosotros, Contáctanos, canales de comunicación Compara assist, envíanos un mensaje, quiero que me contacten, más información, Escríbenos."
            />
            <div className="contact">
                <div className="banner">
                    <img
                        src={ContactBanner}
                        alt="Contact Banner"
                        className="w-100 m-0"
                    />
                    <div className="bg-purple">
                        <div className="w-100 text-center">
                            <p className="text-white p-2">
                                ¿Tienes preguntas acerca de nuestro comparador, proveedores o servicios? Envíanos un mensaje.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="form">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 form-container">
                            {
                                isOk
                                ?
                                    <div className="container w-100 text-center">
                                        <img
                                            src={ContactOkMail}
                                            alt="Compara Assist"
                                            style={{
                                                maxWidth: '17%'
                                            }}
                                        />
                                        <h4 className="text-purple">
                                            Gracias por escribirnos.
                                        </h4>
                                        <p className="text-purple">
                                            Estaremos comunicándonos contigo a la brevedad posible
                                        </p>
                                    </div>
                                :
                                <React.Fragment>
                                    <div className="w-100 text-center">
                                        <h4 className="text-purple"> ¡Escríbenos! </h4>
                                    </div>
                                    <Input
                                        name="name"
                                        onChange={(value: string) => change('name', value)}
                                        value={form.name}
                                        label="Nombre"
                                    />
                                    <Input
                                        name="email"
                                        type="email"
                                        onChange={(value: string) => change('email', value)}
                                        value={form.email}
                                        label="E-Mail"
                                    />
                                    <div className="form-group">
                                        <label className="intl-label">Teléfono</label>
                                        <IntlTelInput
                                            preferredCountries={['tw']}
                                            onChange={onChangeIntl}
                                            className={intlClassName}
                                            // onPhoneNumberBlur={onBlur()}
                                        />
                                    </div>
                                    <Textarea
                                        name="message"
                                        onChange={(value: string) => change('message', value)}
                                        label="Tu Mensaje"
                                        value={form.message}
                                    />
                                    <div className="button-container">
                                        <Button
                                            className="pink"
                                            label="Quiero que me contacten"
                                            onClick={() => submit()}
                                            disabled={!form.name || !form.country || !form.country_phone || !form.email || !form.message}
                                        />
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                        <div className="col-md-6 col-sm-12 bg-light-gray-two right-sidebar">
                            <h4 className="text-purple contact-text p-2 ml-2"> ¡Contáctanos! </h4>
                            <div className="w-100 text-left info-contact-container">
                                <a
                                    href={WHATSAPP_LINK}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-purple whatsapp"
                                >
                                    <Icon
                                        name="whatsapp"
                                    />
                                    &nbsp;
                                    {WHATSAPP_PHONE}
                                </a>
                                <a
                                    href={CALENDLY_LINK}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="w-100 calendly"
                                >
                                    <div className="row">
                                        <div className="col-4 image-container">
                                            <Image
                                                uri="contact.png"
                                            />
                                        </div>
                                        <div className="col-8 text-container">
                                            <p className="text-purple m-0">Agenda una videollamada</p>
                                        </div>
                                    </div>
                                </a>
                                <p className="text-purple">
                                    <Icon
                                        name="envelope p-1"
                                    />
                                    <span>info@comparaassist.com</span>
                                </p>
                            </div>
                            <div className="w-100">
                                <img
                                    src={AirplaneSeparator}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Contact;